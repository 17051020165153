<template>
	<v-row row wrap class="pa-4" :align="fpAlign" :justify="fpJustify">
		<v-col sm="12" md="4" cols="12" v-if="showFree">
			<v-card class="pa-4">
				<v-card-title>Grátis</v-card-title>
				<v-list>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Teste Completo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório Básico</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-close'" color="error"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório Completo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-close'" color="error"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório de Sugestões</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-close'" color="error"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Vídeos Explicativos</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider class="mb-4"></v-divider>

				<div class="text-center">
					<v-btn-toggle v-model="buttonOptions">
						<v-btn color="success" @click="goToLogin()">Faça Agora</v-btn>
						<v-btn color="success" disabled>Grátis</v-btn>
					</v-btn-toggle>
				</div>
			</v-card>
		</v-col>

		<v-col sm="12" md="4" cols="12" v-if="products && products.length > 0">
			<v-card class="pa-4">
				<v-card-title>{{ products[0].name }}</v-card-title>
				<v-list>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Teste Completo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório Básico</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório Completo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-close'" color="error"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório de Sugestões</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-close'" color="error"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Vídeos Explicativos</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider class="mb-4"></v-divider>

				<div class="text-center">
					<v-btn-toggle v-model="buttonOptions2">
						<v-btn color="primary" @click="enterSale(products[0])">Comprar R$ {{ products[0].price }}</v-btn>
						<v-btn v-if="products[0].hasDiscount" color="orange" disabled>-{{ products[0].discount }}% OFF</v-btn>
					</v-btn-toggle>
				</div>
			</v-card>
		</v-col>

		<v-col sm="12" md="4" cols="12" v-if="products && products.length > 0">
			<v-card class="pa-4">
				<v-card-title>{{ products[1].name }}</v-card-title>
				<v-list>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Teste Completo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório Básico</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório Completo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Relatório de Sugestões</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon v-text="'mdi-check'" color="success"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Vídeos Explicativos</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider class="mb-4"></v-divider>

				<div class="text-center">
					<v-btn-toggle v-model="buttonOptions3">
						<v-btn color="primary" @click="enterSale(products[1])">Comprar R$ {{ products[1].price }}</v-btn>
						<v-btn v-if="products[1].hasDiscount" color="orange" disabled>-{{ products[1].discount }}% OFF</v-btn>
					</v-btn-toggle>
				</div>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import currency from 'currency.js'
export default {
	name: 'fac-products',
	props: ['showFree', 'fpAlign', 'fpJustify'],
	data() {
		return {
			buttonOptions: null,
			buttonOptions2: null,
			buttonOptions3: null,
			products: []
		}
	},
	mounted() {
		this.fetchProducts()
	},
	methods: {
		BRL(value, precision) {
			return currency(value, { precision: precision || 2, separator: ".", decimal: ",", symbol: "R$ " })
		},
		fetchProducts() {
			this.$store.dispatch('fetchProducts').then(result => {
				if (result && result.length > 0) {
					for (let product of result) {
						this.products.push({
							code: product.code,
							name: product.name,
							price: product.price ? this.BRL(product.price).format() : product.price,
							hasDiscount: product.hasDiscount,
							discount: product.discount
						})
					}
				}
			})
		},
		enterSale(product) {
			if (product && product.code) {
				sessionStorage.setItem('fac-product', product.code)
				this.$router.push('/comprar')
			}
		},
		goToLogin() {
			this.$router.push('/entrar')
		}
	}
}
</script>