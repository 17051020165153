<template>
	<v-container no-gutters fluid>
		<div class="pa-4" v-if="loading">
			<v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
		</div>

		<p class="mb-0 text-h5 white--text">Bem vindo ao {{ gameParameters.description }}</p>
		<v-row row wrap justify-center v-if="!loading">
			<v-col cols="12" v-if="reportGames.length > 0">
				<p class="mt-0 text-subtitle-2 grey--text text--lighten-1">Que legal! Seu resultado já está disponível. Veja seu relatório abaixo:</p>

				<v-row row wrap>
					<v-col sm="12" md="3" cols="12" v-for="game in reportGames" :key="game.id">
						<v-card>
							<v-card-title>Relatório {{ game.id }} <span v-if="game.isExpired" class="caption error--text font-italic">&nbsp;&nbsp;vencido</span></v-card-title>
							<v-card-subtitle>Início: {{ formatDate(game.dateStarted) }}<br />Término {{ formatDate(game.dateFinished) }}</v-card-subtitle>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" :disabled="game.isExpired" depressed small @click="viewReport(game)">Ver Relatório</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" v-if="canStart">
				<p class="mb-0 text-h6 grey--text text--lighten-1">Começar</p>
				<p class="mt-0 text-subtitle-2 grey--text text--lighten-1">Ao iniciar o teste, você terá {{ gameParameters.timeLimit }} minuto{{ gameParameters.timeLimit > 1 ? 's' : '' }} para terminar.</p>

				<v-btn color="success" @click="startGame()">Iniciar</v-btn>
			</v-col>

			<v-col cols="12" v-if="canBuy">
				<p class="mb-0 text-h6 grey--text text--lighten-1">Fazer o teste novamente</p>
				<p class="mt-0 text-subtitle-2 grey--text text--lighten-1">Você poderá fazer o teste novamente comprando um dos produtos abaixo.</p>
				<fac-products :showFree="false"></fac-products>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from 'moment'
import FacProducts from '../components/items/Product.vue'

export default {
	name: "Home",
	components: {
		'fac-products': FacProducts
	},
	data() {
		return {
			loading: true,
			userData: [],
			gameParameters: [],
			userGames: [],
			lastGame: [],
			finishedGames: [],
			prestineGames: [],
			reportGames: [],
			canBuy: false,
			canStart: false,
			canContinue: false,
		}
	},
	mounted() {
		this.fetchData()
		this.$bus.$emit('DISABLE_TIMER')
	},
	methods: {
		fetchData() {
			this.loading = true
			Promise.resolve().then(() => {
				return this.$store.dispatch('checkAuth').then(result => {
					if (result && result.content && result.content.id) {
						this.userData = result.content
					}
				})
			}).then(() => {
				return this.$store.dispatch('fetchGameFACParameters').then(result => {
					if (result && result.id) {
						this.gameParameters = result
					}
				})
			}).then(() => {
				if (this.userData && this.userData.id) {
					return this.$store.dispatch('fetchUserGameProgress', this.userData.id).then(result => {
						if (result && result.length > 0) {
							// CHECK GAMES
							this.userGames = result
							for (let game of this.userGames) {
								if (game.id && game.progress && game.progress.id) {
									// VALID GAME
									if (game.isInProgress && game.dateStarted) {
										// SHOULD REDIRECT TO GAME IN PROGRESS
										this.canContinue = true
										this.$router.push('/jogos/fac')
									}
									else if (game.isFinished && !game.isInProgress && game.dateStarted && game.dateFinished) {
										this.finishedGames.push(game)
									}
									else if (!game.isFinished && !game.isInProgress && !game.dateStarted && !game.dateFinished) {
										this.prestineGames = [game]
									}
								}
							}

							// VALIDATE
							if (!this.canContinue) {
								if (this.finishedGames.length > 0 && this.prestineGames.length > 0) {
									let hasPrototype = false
									let prestinePaid = false
									for (let game of this.finishedGames) {
										if (game.accessType === 4) {
											hasPrototype = true
										}
									}

									if (!hasPrototype) {
										for (let game of this.prestineGames) {
											if (game.isPaid) {
												prestinePaid = true
											}
										}
									}

									if (!hasPrototype) {
										// FINISHED A GAME, CAN BUY A NEW ONE
										if (prestinePaid) {
											this.canBuy = false
											this.canStart = true
										}
										else {
											this.canBuy = true
											this.canStart = false
										}

										for (let game of this.finishedGames) {
											this.reportGames = [game]
										}
									}
									else {
										this.canStart = true
										this.canBuy = false

										for (let game of this.finishedGames) {
											this.reportGames.push(game)
										}
									}
								}
								else if (this.finishedGames.length === 0 && this.prestineGames.length > 0) {
									// DID NOT FINISH ANY GAME
									this.canBuy = false
									this.canStart = true
								}
							}

							// CHECK GAMES

							/*
							this.lastGame = result[result.length - 1]
							if (this.lastGame.id && this.lastGame.progress && this.lastGame.progress.id) {
								// VALID GAME
								if (!this.lastGame.isFinished && this.lastGame.isInProgress) {
									// SHOULD REDIRECT TO GAME IN PROGRESS, CHECK EXPIRATION ON GAME
									this.$router.push('/jogos/fac')
								}
								else if (!this.lastGame.isFinished && !this.lastGame.isInProgress) {
									if (this.finishedGames.length === 0) {
										this.game.canStart = true
									}
									else if (this.lastGame.accessType === 4 && this.finishedGames.length > 0) {
										this.game.canStart = true
									}
								}
							}
							*/
						}
					})
				}
				else {
					this.loading = false
				}
			}).then(() => {
				this.loading = false
			}).catch(() => {})
		},
		formatDate(date) {
			return moment(date).format('DD/MM/YYYY HH:mm')
		},
		startGame() {
			this.$router.push('/jogos/fac')
		},
		viewReport(data) {
			this.$router.push(`/jogos/fac/relatorio?id=${data.id}&u=${data.code}`)
		}
	}
}
</script>
